<template>
	<StatsLayout @search="search" @apply-filters="filter_data">
		<div style="display: flex; margin-top: 10px;">
			<button @click="generateCSV" style="margin-right: 10px">
				<fa icon="download"/> Exporter
			</button>
			<button @click="printTable">
				<fa icon="print"/> Imprimer
			</button>
		</div>

		<div class="table">
			<table id="stockTable">
				<thead>
					<tr>
						<th @click="orderBy('produit')">Produit</th>
						<th @click="orderBy('produit.prix_vente')">P.V.U.</th>
						<th @click="orderBy('quantite_initiale')">Qtt. Initiale</th>
						<th @click="orderBy('quantite_actuelle')">Qtt. Restant</th>
						<th @click="orderBy('prix_total')">Prix d'Achat</th>
						<th @click="orderBy('date_expiration')">Date d'Exp.</th>
						<th @click="orderBy('date')">Date</th>
						<th @click="orderBy('user')">User</th>
						<th @click="orderBy('validated_by')">Validateur</th>
						<th>Option</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="stock in stocks" :key="stock.id">
						<td>{{ stock.produit.nom }}</td>
						<td>{{ money(stock.produit.prix_vente) }}</td>
						<td>{{ `${stock.quantite_initiale} ${stock.produit.unite}` }}</td>
						<td><b>{{ `${stock.quantite_actuelle} ${stock.produit.unite}` }}</b></td>
						<td>{{ money(stock.prix_total) }}</td>
						<td>{{ stock.date_expiration || "-" }}</td>
						<td>{{ stock.date || "-" }}</td>
						<td>{{ stock.user }}</td>
						<td>{{ stock.validated_by }}</td>
						<td v-if="!stock.validated_by">
							<button @click="valider(stock)">Valider</button>
							<button class="delete" @click="supprimer(stock)">Supprimer</button>
						</td>
						<td v-else>
							<button @click="perdre(stock)">Perte</button>
							<button @click.stop="editStock(stock)">Modifier</button>
						</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<td colspan="1"></td>
						<td>{{ money(totalPVU) }}</td>
						<td colspan="2"></td>
						<td>{{ money(totalPrixAchat) }}</td>
						<td colspan="5"></td>
					</tr>
				</tfoot>
			</table>
		</div>

		<DialogStock
			:active="stock_shown"
			:produit="active_stock"
			:item="active_stock"
			@close="close"/>
		<DialogPerte :active="perte_shown" :item="active_stock" @close="close" />
	</StatsLayout>
</template>

<script>
import StatsLayout from "./stats_layout";
import DialogPerte from "../components/dialog_perte";
import DialogStock from "../components/dialog_stock";

export default {
	components: { StatsLayout, DialogPerte, DialogStock },
	data() {
		return {
			stocks: this.$store.state.stocks,
			progress: false,
			next: null,
			active_stock: null,
			perte_shown: false,
			stock_shown: false,
			order: ""
		};
	},
	watch: {
		"$store.state.stocks"(new_val) {
			this.stocks = new_val;
		}
	},
	methods: {
		filter_data(arg) {
			this.fetchData(arg.date);
		},
		close() {
			this.stock_shown = false;
			this.perte_shown = false;
			this.active_stock = null;
		},
		perdre(product) {
			this.perte_shown = true;
			this.active_stock = product;
		},
		search(keyword) {
			this.stocks = this.$store.state.stocks.filter(x =>
				JSON.stringify(x).toLowerCase().includes(keyword.toLowerCase())
			);
		},
		compareStrings(a, b, order) {
			if (a[order] > b[order]) {
				return 1;
			} else if (a[order] === b[order]) {
				return 0;
			} else {
				return -1;
			}
		},
		orderBy(order) {
			if (this.order === order) {
				this.stocks.sort((a, b) =>
					typeof a[order] === "string"
						? this.compareStrings(a, b, order)
						: a[order] - b[order]
				);
				this.order = "";
			} else {
				this.stocks.sort((a, b) =>
					typeof a[order] === "string"
						? this.compareStrings(b, a, order)
						: b[order] - a[order]
				);
				this.order = order;
			}
		},
		valider(stock) {
			let index = this.$store.state.stocks.findIndex(s => s.id === stock.id);
			if (confirm(`Voulez-vous vraiment valider ce stock ?`)) {
				axios.get(`${this.url}/stock/${stock.id}/valider/`, this.headers)
					.then(response => {
						this.$store.state.alert = {
							type: "success",
							message: "Le stock a été validé"
						};
						this.$store.state.stocks[index] = response.data;
						window.location.reload();
					})
					.catch(error => {
						this.displayErrorOrRefreshToken(error, () => this.valider(stock));
					});
			}
		},
		supprimer(stock) {
			if (confirm(`Voulez-vous vraiment supprimer ce stock ?`)) {
				axios.delete(`${this.url}/stock/${stock.id}/`, this.headers)
					.then(() => {
						let index = this.stocks.indexOf(stock);
						this.stocks.splice(index, 1);
					})
					.catch(error => {
						this.displayErrorOrRefreshToken(error, () => this.supprimer(stock));
					});
			}
		},
		editStock(stock) {
			this.stock_shown = true;
			this.active_stock = stock;
		},
		generateCSV() {
			let data = "sep=;\n";
			let keys = Object.keys(this.stocks[0]);
			keys.push("p_v_u");
			for (let key of keys) {
				data += `${key};`;
			}
			data += "\n";
			for (let stock of this.stocks) {
				for (let key of keys) {
					if (key === "produit") {
						data += `${stock[key].nom};`;
					} else if (key === "p_v_u") {
						data += `${stock.produit.prix_vente};`;
					} else {
						data += `${stock[key]};`;
					}
				}
				data += "\n";
			}
			window.location = "data:text/csv;base64,77u/" + btoa(data);
		},
		printTable() {
			const stockTable = document.getElementById("stockTable");
			const printContent = stockTable.outerHTML;
			const printWindow = window.open("", "", "width=800,height=600");
			printWindow.document.write("<html><head><title>Stock</title>");
			printWindow.document.write(
				"<style>tfoot, td:nth-child(10), th:nth-child(10) { display: none; }</style>"
			);
			printWindow.document.write("</head><body>");
			printWindow.document.write(printContent);
			printWindow.document.write("</body></html>");
			printWindow.document.close();
			printWindow.focus();
			printWindow.print();
			printWindow.close();
		},
		fetchData(date = "") {
			let link = "";
			const kiosk_id = this.getActiveKiosk().id;
			const last_id =
				this.stocks.length > 0
					? Math.max(...this.stocks.map(stock => stock.id))
					: 0;

			if (!this.next) {
				link = `${this.url}/stock/?produit__kiosk=${kiosk_id}${
					date ? `&date=${date}` : ""
				}&id__gt=${last_id}`;
			} else {
				link = this.next;
			}

			axios
				.get(link, this.headers)
				.then(response => {
					const newStocks = response.data.results;
					this.$store.state.stocks = [
						...this.$store.state.stocks,
						...newStocks
					];

					if (response.data.next && response.data.next.length > 0) {
						this.next = response.data.next;
						this.fetchData();
					} else {
						this.next = null;
					}
				})
				.catch(error => {
					this.displayErrorOrRefreshToken(error, this.fetchData);
				});
		}
	},
	mounted() {
		if (this.$store.state.stocks.length < 1) {
			this.fetchData();
		}
	},
	computed: {
		totalPVU() {
			return this.stocks.reduce(
				(sum, stock) => sum + (stock.produit.prix_vente || 0),
				0
			);
		},
		totalPrixAchat() {
			return this.stocks.reduce(
				(sum, stock) => sum + (stock.prix_total || 0),
				0
			);
		}
	}
};
</script>

<style scoped>

thead th,
tfoot td {
    background-color: #f4f4f4;
}

tfoot td {
    font-weight: bold;
    white-space: nowrap;
}


</style>
