<template>
	<StatsLayout @search="search" @apply-filters="filter_data">
		<div class="option">
			<button @click="printTable">Imprimer</button>
		</div>
		<div class="option">
			<label for="dette">Les dettes seulement</label>
			<input type="checkbox" id="dette" v-model="only_dettes">
		</div>
		<div class="table nonprintable">
			<table>
				<thead>
					<tr>
						<th>No.</th>
						<th>date</th>
						<th>caissier</th>
						<th>client</th>
						<th>produit</th>
						<th class="right">somme</th>
						<th class="right">reste</th>
						<th class="right">benefice</th>
						<th>options</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in filteredCommandes" :key="item.id">
						<td>{{ item.id }}</td>
						<td>{{ datetime(item.date) }}</td>
						<td>{{ item.user }}</td>
						<td>{{ item.client.nom }} | {{ item.client.tel }} | {{ item.client.NIF }}</td>
						<td>
							<span v-for="vente in item.ventes" :key="vente.id">{{ vente.produit }}</span>
						</td>
						<td class="right">{{ money(item.prix) }} FBu</td>
						<td class="right">{{ money(item.prix - item.payee) }} FBu</td>
						<td class="right">{{ money(item.prix - item.prix_achat) }} FBu</td>
						<td class="left">
							<button @click="showDetails(item)">details</button>
							<button class="red" @click="supprimer(item)">Supprimer</button>
							<button v-if="item.payee < item.prix" @click="payer(item)">Payer</button>
						</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<th colspan="5"></th>
						<th class="right">{{ money(filteredCommandes.reduce((acc, x) => acc + x.prix, 0)) }} FBu</th>
						<th class="right">{{ money(filteredCommandes.reduce((acc, x) => acc + (x.prix - x.payee), 0)) }} FBu</th>
						<th class="right">{{ money(filteredCommandes.reduce((acc, x) => acc + (x.prix - x.prix_achat), 0)) }} FBu</th>
						<th colspan="1"></th>
					</tr>
				</tfoot>
			</table>
		</div>
		<DialogVentes :active="ventes_shown" :commande="active_command" @close="close"/>
		<DialogPayment :active="payment_shown" :commande="active_command" @close="close"/>
	</StatsLayout>
</template>

<script>
import StatsLayout from "./stats_layout"
import DialogVentes from "../components/dialog_ventes"
import DialogPayment from "../components/dialog_payment"
import axios from 'axios';

export default {
	props: {
    	commandes: {
			type: Array,
		},
	},
	components: { StatsLayout, DialogVentes, DialogPayment },
	data() {
		return {
			ventes_shown: false,
			active_command: null,
			next: null,
			payment_shown: false,
			only_dettes: false,
			selectedDate: null,
			keyword: "",
			filteredCommandes: [],
		}
	},
	computed: {
		commandes() {
			return this.$store.state.commandes;
		},
	},
	watch: {
		commandes: {
			handler() {
				this.applyFilter();
			},
			deep: true
		},
		only_dettes: "applyFilter"
	},
	methods: {
		applyFilter() {
			this.filteredCommandes = this.only_dettes 
				? this.commandes.filter(x => x.prix > x.payee) 
				: this.commandes;
		},
		filter_data(arg) {
			this.fetchData(arg.date);
		},
		close() {
			this.ventes_shown = false;
			this.active_command = null;
			this.payment_shown = false;
		},
		search(keyword) {
			this.filteredCommandes = this.commandes.filter(item => {
				const searchKeys = ["produit", "client", "caissier"];
				for (const key of searchKeys) {
					if (item[key] && item[key].toString().toLowerCase().includes(keyword.toLowerCase())) {
						return true;
					}
				}
				return false;
			});
		},
		printTable() {
			const printContent = `
				<table>
					<thead>
						<tr>
							<th>No.</th>
							<th>date</th>
							<th>caissier</th>
							<th>client</th>
							<th>produit</th>
							<th class="right">somme</th>
							<th class="right">reste</th>
							<th class="right">benefice</th>
						</tr>
					</thead>
					<tbody>
						${this.filteredCommandes.map(item => `
							<tr>
								<td>${item.id}</td>
								<td>${this.datetime(item.date)}</td>
								<td>${item.user}</td>
								<td>${item.client}</td>
								<td>${item.ventes}</td>
								<td class="right">${this.money(item.prix)} FBu</td>
								<td class="right">${this.money(item.prix - item.payee)} FBu</td>
								<td class="right">${this.money(item.prix - item.prix_achat)} FBu</td>
							</tr>
						`).join('')}
					</tbody>
				</table>
			`;

			const newWindow = window.open('', '', 'height=600,width=800');
			newWindow.document.write('<html><head><title>Impression</title>');
			newWindow.document.write('<style>table {width: 100%; border-collapse: collapse;} th, td {border: 1px solid black; padding: 5px; text-align: left;} th {background-color: #f2f2f2;}</style>');
			newWindow.document.write('</head><body>');
			newWindow.document.write('<h2>Historique des Ventes</h2>');
			newWindow.document.write(printContent);
			newWindow.document.write('</body></html>');
			newWindow.document.close();
			newWindow.print();
		},
		showDetails(commande) {
			this.ventes_shown = true;
			this.active_command = commande;
		},
		payer(commande) {
			this.payment_shown = true;
			this.active_command = commande;
		},
		supprimer(item) {
			if (confirm("Voulez-vous vraiment annuler cette commande?")) {
				axios.delete(`${this.url}/commande/${item.id}/`, this.headers)
					.then(() => {
						let index = this.$store.state.commandes.indexOf(item);
						this.$store.state.commandes.splice(index, 1);
						this.$store.state.alert = {
							type: "success", 
							message: "Commande supprimée avec succès !"
						};
					}).catch(error => {
						this.displayErrorOrRefreshToken(error, () => this.supprimer(item));
						this.$store.state.alert = {
                            type: "danger",
                            message: "Seuls le gerant ou le proprietaire peuvent supprimer une commande avant un jour"
                        };
					});
			}
		},
		fetchData(date = '', data = []) {
			let link = "";
			let kiosk_id = this.getActiveKiosk().id;
			if (!this.next) {
				link = this.url + `/commande/?kiosk=${kiosk_id}${date ? `&date=${date}` : ''}`;
			} else {
				link = this.next;
			}
			axios.get(link, this.headers)
				.then(response => {
					data = data.concat(response.data.results)
					if (response.data.next) {
						this.next = response.data.next;
						this.fetchData(date, data);
					} else {
						this.next = null;
						this.$store.state.commandes = data
					}
				}).catch(error => {
					this.displayErrorOrRefreshToken(error, this.fetchData);
				});
		}
	},
	mounted() {
		if (this.$store.state.commandes.length < 1) {
			this.fetchData();
		}
		this.applyFilter();
	}
};
</script>

<style scoped>
.option {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
#dette {
	margin-left: 5px;
}
/* .table {
	height: calc(100vh - 160px);
} */
</style>
