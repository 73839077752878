<template>
    <div class="facture_container">
        <div class="facture_header">
            <div class="facture_left">
                <img class="pharma_logo" :src="getActiveKiosk().logo" alt="LOGO">
                <div>
                    <span>NIF : {{ getActiveKiosk().NIF}}</span>
                    <span>RC : {{ getActiveKiosk().RC }}</span>
                </div>
            </div>
            <div class="facture_right">
                <span>Adresse :  Bujumbura-centre Ville,Avenue de la Revolution No.9 </span>
                <span>Tel : {{ getActiveKiosk().tel }}</span>
                <span>Email : {{ getActiveKiosk().email }}</span>
                <span>Compte : BANCOBU (19860820101 (BIF)/19860820201-75 (USD) / 19860820202-48 (EURO))  </span>
            </div>
        </div>
        <div>
            <div class="client">
                <span v-if="commande"> CLIENT : {{ commande.client.nom }}</span>  <span>DATE : {{ current_date }} </span>
                <span v-if="commande"> NIF : {{ commande.client.NIF }}</span>  <span> </span>
            </div>
            <h4>FACTURE N° {{ get_numero() + '/' + getActiveKiosk().nom + date_time }}</h4>
        </div>
        <div class="facture_table_container">
            <table v-if="commande">
                <tr>
                    <th>Article</th>
                    <th>P.U.</th>
                    <th>Qt.</th>
                    <th>Total</th>
                </tr>
                <tr v-for="item in commande?.ventes">
                    <td>{{ item.produit }}</td>
                    <td>{{ item.prix_unitaire }} Fbu</td>
                    <td>x {{ item.quantite }}</td>
                    <td>{{ item.prix_total }} Fbu</td>
                </tr>
                <tr>
                    <th colspan="3">Total</th>
                    <th><b>{{ money(commande.prix) }} Fbu</b></th>
                </tr>
            </table>
            <div v-if="commande">
                Caissier: {{ commande.user }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        date_time() {
            const now = new Date();
            const day = String(now.getDate()).padStart(2, '0');
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const year = now.getFullYear();
            return `${day}${month}/${year}`
        },
        current_date(){
            const now = new Date();
            const date = now.toLocaleDateString();
            return date
        },
        

    },
    props:["commande"],
    data(){
        return {
            ventes:[]
        }
    },
    methods : {
        get_numero() {
            const now = new Date();
            const hours = String(now.getHours()).padStart(2, '0'); 
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
            return `${hours}${minutes}${seconds}`
        }
    }
};
</script>
<style src="./facture.css" scoped></style>